
body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

header {
  nav > .btn {
    font-family: 'Oswald';
    font-weight: 700;
    border-radius: calc(.25rem - 1px);
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 8px;
    margin: .25rem .15rem;
    i {
      font-size: 19px;
      padding-right: 0.5rem;
      margin-right: 0.25rem;
      border-right: 1px dashed rgba(#fff, .4);
    }
    &:hover i {
        border-right: 1px solid rgba(#fff, .8);
    }
  }

}

@media screen and (max-width: 767px) {
  .c36 {
    height: 70px;
  }
}

main {
  flex: 1;
  .title {
    h1 {
      font-family: 'Oswald';
    }
    .icon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 2px solid #fafafa;
      i {
        padding-right: 5px;
      }
      &:after {
        position: absolute;
        content: '';
        width: 15px;
        height: 15px;
        z-index: 10;
        right: calc((-15px / 2) - 1.5px);
        top: calc(50% - (15px / 2));
        transform: rotate(45deg);
        border-top: 2px solid #fafafa;
        border-right: 2px solid #fafafa;
        background-color: #4c6a92;
        outline: none;
      }
    }
    .bg-navy:after {
      background: #546778;
    }
  }
}

footer {
  background: url('../../public/images/coureurs.png') center top repeat-x;
  background-size: auto 100px;
  width: 100%;
  min-height: 125px;
  padding-top: 99px;
  margin-top: 2em;
  section {
    border-top: 5px solid #4c6a92;
    padding: 1em;
    text-align: center;
    color: #fff;
    background-color: #5eabc4;
    a:link, a:hover, a:visited {
      text-decoration: none;
      color: #4c6a92;
    }
    a:hover {
      color: #fff;
    }
  }
}

.required {
  border: 1px solid #546778;
}
.custom-file-button {
	input[type="file"] {
		margin-left: -2px !important;
    padding-left: 1rem;

		&::-webkit-file-upload-button {
			display: none;
		}
		&::file-selector-button {
			display: none;
		}
	}
  label {
    cursor: pointer;
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem)
  }
}

.pointer {
  cursor: pointer;
}

.ff-roboto {
  font-family: 'Roboto Mono';
}
.ff-oswald {
  font-family: 'Oswald';
}
.ff-nunito {
  font-family: 'Nunito';
}
.ff-muli {
  font-family: 'Muli';
}
.ff-lato {
  font-family: 'Lato';
}


.title {
  h1 {
    font-family: 'Oswald';
  }
  .icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 2px solid #fafafa;
    i {
      padding-right: 5px;
    }
    &:after {
      position: absolute;
      content: '';
    	width: 15px;
    	height: 15px;
    	z-index: 3;
    	right: calc((-15px / 2) - 1.5px);
    	top: calc(50% - (15px / 2));
    	transform: rotate(45deg);
    	border-top: 2px solid #fafafa;
    	border-right: 2px solid #fafafa;
      outline: none;
    }
  }
  .bg-navy:after {
    background: #546778;
  }
}

.btn {
  box-shadow: none!important;
  font-family: 'Oswald';
  font-size: 1rem;
  letter-spacing: .1rem;
  border-radius: .25rem;
  padding: .25rem .5rem;
}
.btn-lg {
  font-size: 1.5rem;
  border-radius: .25rem;
  padding: .25rem .5rem;
}
.btn-sm {
  font-size: .75rem;
  border-radius: .15rem;
  padding: .25rem .35rem;
}
.btn-xs {
  font-size: .65rem;
  border-radius: .15rem;
  padding: .15rem .25rem;
  margin-top: .1rem;
}
.btn-text-hover {
  span {
    display: none;
  }
  &:hover span {
    display: inline;
  }
}
